@charset "UTF-8";
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-two {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-three {
  -webkit-animation-duration: 100ms;
  animation-duration: 100ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-four {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #3e8058;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #3e8058;
    box-shadow: 0 0 0 0 #3e8058;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

._pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

.pulsing {
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
}

@-webkit-keyframes pulsing {
  0% {
    -webkit-box-shadow: 0 0 0 0 #003145;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulsing {
  0% {
    -moz-box-shadow: 0 0 0 0 #003145;
    box-shadow: 0 0 0 0 #003145;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.pulsing2 {
  animation: pulsing 2s infinite;
  -webkit-animation: pulsing 2s infinite;
}

@-webkit-keyframes pulse1 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse1 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse1 {
  animation: pulse1 1s infinite;
  -webkit-animation: pulse1 1s infinite;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
    visibility: hidden;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
    visibility: hidden;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes riseTitle {
  from {
    transition: 0.72s cubic-bezier(0.52, 0.01, 0.16, 1);
    width: 0;
  }
  to {
    width: 24px;
  }
}

@keyframes riseTitle {
  from {
    transition: 0.72s cubic-bezier(0.52, 0.01, 0.16, 1);
    width: 0;
  }
  to {
    width: 24px;
  }
}

.rise-title {
  -webkit-animation-name: riseTitle;
  animation-name: riseTitle;
}

@font-face {
  font-family: 'Kozuca Regular';
  src: url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Heavy';
  src: url("/assets/fonts/KozGoPr6N-Heavy-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Heavy-AlphaNum.otf") format("opentype"), url("/assets/fonts//KozGoPr6N-Heavy-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Heavy-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Medium';
  src: url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Light';
  src: url("/assets/fonts/KozGoPr6N-Light-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Light-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Light-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Light-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca ExtraLight';
  src: url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Bold';
  src: url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/material.woff2") format("woff2");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.btn-one,
.btn-two,
.btn-custom-one {
  color: #FFF !important;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  padding: 11px 18px;
  font-size: 13px;
  text-decoration: none;
  outline: none !important;
  font-family: "Kozuca ExtraLight";
  border: 0;
  cursor: pointer !important;
}

.btn-one {
  background: #003145;
}

.btn-two {
  background: #3e8058;
}

.btn-three {
  background: #FFF;
  outline: none !important;
  border-radius: 7px;
  color: #3e8058;
  border: 1px solid #3e8058;
  font-size: 12px;
  padding: 12px 30px;
}

.btn-two.radius {
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 15px 20px;
}

.btn-custom-one {
  background: #003145;
  min-width: 170px;
  text-align: left;
  position: relative;
  display: table;
}

.btn-custom-one span {
  font-size: 10px;
}

.btn-custom-one mat-icon {
  background: #848484;
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  top: 0;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 0 0 7px;
}

.btn-custom-two {
  border-radius: 30px;
  color: #d96100;
  background: transparent;
  font-family: "Kozuca Bold";
  text-transform: uppercase;
  font-size: 13px;
  padding: 15px 60px;
  border: 2px solid #d96100;
  -webkit-transition: background 300ms ease;
  -moz-transition: background 300ms ease;
  -ms-transition: background 300ms ease;
  -o-transition: background 300ms ease;
  transition: background 300ms ease;
}

.btn-custom-two:hover {
  background: #d96100;
  color: #FFF;
}

@font-face {
  font-family: 'Kozuca Regular';
  src: url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Regular-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Heavy';
  src: url("/assets/fonts/KozGoPr6N-Heavy-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Heavy-AlphaNum.otf") format("opentype"), url("/assets/fonts//KozGoPr6N-Heavy-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Heavy-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Medium';
  src: url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Medium-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Light';
  src: url("/assets/fonts/KozGoPr6N-Light-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Light-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Light-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Light-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca ExtraLight';
  src: url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuca Bold';
  src: url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.otf") format("opentype"), url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.woff") format("woff"), url("/assets/fonts/KozGoPr6N-Bold-AlphaNum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/material.woff2") format("woff2");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.btn-one,
.btn-two,
.btn-custom-one {
  color: #FFF !important;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  padding: 11px 18px;
  font-size: 13px;
  text-decoration: none;
  outline: none !important;
  font-family: "Kozuca ExtraLight";
  border: 0;
  cursor: pointer !important;
}

.btn-one {
  background: #003145;
}

.btn-two {
  background: #3e8058;
}

.btn-three {
  background: #FFF;
  outline: none !important;
  border-radius: 7px;
  color: #3e8058;
  border: 1px solid #3e8058;
  font-size: 12px;
  padding: 12px 30px;
}

.btn-two.radius {
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 15px 20px;
}

.btn-custom-one {
  background: #003145;
  min-width: 170px;
  text-align: left;
  position: relative;
  display: table;
}

.btn-custom-one span {
  font-size: 10px;
}

.btn-custom-one mat-icon {
  background: #848484;
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  top: 0;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 0 0 7px;
}

.btn-custom-two {
  border-radius: 30px;
  color: #d96100;
  background: transparent;
  font-family: "Kozuca Bold";
  text-transform: uppercase;
  font-size: 13px;
  padding: 15px 60px;
  border: 2px solid #d96100;
  -webkit-transition: background 300ms ease;
  -moz-transition: background 300ms ease;
  -ms-transition: background 300ms ease;
  -o-transition: background 300ms ease;
  transition: background 300ms ease;
}

.btn-custom-two:hover {
  background: #d96100;
  color: #FFF;
}

h2, h3, h4 {
  font-family: "Kozuca Medium";
}

header.default-home .menu a {
  color: #FFF !important;
}

header.fixed .menu a {
  color: #003145 !important;
}

header.default-home .logo-1 {
  display: none;
}

header.fixed .logo-1 {
  display: block;
  margin: 5px 0 0 0;
}

header.fixed {
  background: #FFF !important;
  -webkit-box-shadow: 0 0 20px 0 #B3B3B3;
  box-shadow: 0 0 20px 0 #B3B3B3;
}

header.fixed .content {
  padding: 10px 0 !important;
}

header.default-home .logo-2 {
  display: block;
}

header.fixed .logo-2 {
  display: none;
}

.text-center {
  text-align: center;
}

.section-title-h2 {
  font-family: "Kozuca Bold";
  font-size: 22px;
  margin: 0 0 10px 0;
}

.seta-box {
  border-radius: 100%;
  background: #FFF;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 10px 0 #d4d4d4;
  cursor: pointer;
}

.seta-box img {
  width: 15px;
  margin: 0 auto;
}

.element-shape {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.element-shape svg {
  display: block;
  width: calc(160% + 1.3px);
  height: 120px;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
}

.element-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

svg:not(:root) {
  overflow: hidden;
}

.modulo-chamada article strong {
  font-family: "Kozuca Bold";
}

.linha-title {
  display: table;
  margin: 0 auto;
  width: 24px;
  height: 7px;
  background: #d96100;
  transform: skewY(-16deg) scaleX(1);
}

body {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
  font-family: "Kozuca Regular";
}

.modulo-parceria .descricao p {
  margin: 0;
}

/** MODAIS **/
.mat-dialog-container {
  border-radius: 0;
  padding: 10px 24px !important;
}

/** CONTATO CONSULTOR **/
.modulo-contato-consultor .descricao {
  color: #FFF;
}

.modulo-contato-consultor .descricao h2 {
  font-size: 22px;
}

.modulo-contato-consultor .descricao h3 {
  font-size: 16px;
  font-family: "Kozuca Regular";
}

.modulo-contato-consultor .descricao strong {
  color: #3e8058;
}

/** MÓDULOS SERVICOS **/
.modulo-servicos h2 strong {
  font-family: "Kozuca Bold";
}

/** PAGE **/
.page {
  padding: 40px 0;
  min-height: 500px;
}

.page .page-title {
  margin: 0 0 20px 0;
}

.page .page-title h1 {
  font-size: 55px;
  color: #a6a5a5;
  font-family: "Kozuca ExtraLight";
}

.page p {
  margin: 0;
}

.page .page-title h2 {
  font-size: 16px;
  color: #333;
  font-family: "Kozuca Regular";
}

a {
  color: #003145;
  text-decoration: none !important;
}

.item-funcionalidade p {
  margin-bottom: 4px;
  padding-bottom: 4px;
}

.item-funcionalidade ul {
  padding-left: 20px;
}

.item-funcionalidade ul li {
  font-size: 14px;
}

/** BANNER SLIDER **/
/** BANNER SLIDER SLICK **/
.banner-slider-slick .slick-dots,
.produto-slide-show .slick-dots {
  bottom: 15px;
}

.banner-slider-slick .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.81);
  border-radius: 100%;
  border: 1px solid #3e8058;
}

.banner-slider-slick .slick-dots li {
  width: 5px;
  margin: 0 7px;
}

.banner-slider-slick .slick-dots li button:before {
  content: "" !important;
}

.banner-slider-slick .slick-dots .slick-active button {
  background: #003145;
}

.center {
  display: table;
  margin: 0 auto;
}

/** MATERIAL **/
.mat-radio-button {
  margin: 0 10px 0 0;
}

.mat-radio-label-content {
  font-size: 14px;
  color: #003145;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #003145 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #003145 !important;
}

.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}

/** FOOTER **/
.footer h3 {
  color: #003145;
  font-size: 20px !important;
  font-family: "Kozuca ExtraLight";
  margin: 0 0 30px 0;
  text-transform: uppercase !important;
}

.footer .modulo {
  margin: 0 0 20px 0;
}

/** DIVISOR TITULO **/
.divisor-titulo {
  display: table;
  margin: 0 auto;
}

.divisor-titulo span {
  height: 3px;
  width: 12px;
  display: table;
  float: left;
}

.divisor-titulo span:nth-child(odd) {
  background: #003145;
}

.divisor-titulo span:nth-child(even) {
  background: #fafafa;
}

/** GALERIA **/
.lg-actions .lg-next, .lg-actions .lg-prev {
  color: #003145;
  background: #3e8058;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin-top: -25px;
  width: 40px;
  height: 40px;
  line-height: 20px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  z-index: 1080;
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.2);
}

.open-menu {
  overflow: hidden !important;
}

/** FORM CUSTOM **/
/** FORM CUSTOM **/
.form-custom label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #878C9F;
  font-size: 13px;
  font-weight: 500;
}

.form-custom label i {
  padding-right: 12px;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.form-custom input[type="text"],
.form-custom input[type="password"],
.form-custom input[type="file"],
.form-custom textarea,
.form-custom mat-select {
  float: left;
  border: none;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #F7F9FB !important;
  width: 100%;
  padding: 10px 20px 10px 13px;
  color: #000;
  font-size: 13px;
  -webkit-appearance: none;
  outline: none !important;
  margin: 0 0 10px 0;
}

.form-custom textarea {
  resize: none;
  padding: 21px 17px;
  -webkit-appearance: none;
}

.form-custom mat-select .mat-select-value-text {
  position: relative;
  top: 0;
  font-size: 12px;
}

.form-custom .mat-form-field-underline {
  display: none;
}

.form-custom .form-group {
  display: table;
  width: 100% !important;
}

.post-content iframe {
  width: 100% !important;
  min-width: 100% !important;
}

.message p {
  margin: 0 !important;
}

/** SELECT **/
.select2 {
  display: table;
  width: 100% !important;
}

.select2 .select2-selection {
  display: table !important;
}

.select2 .select2-selection__arrow b {
  margin-top: 20% !important;
}

.select2-selection {
  background-color: transparent !important;
  border: 0 !important;
  padding: 8px 0 8px 5px !important;
  border-bottom: 1px solid #b2b2b2 !important;
  border-radius: 0 !important;
  font-size: 16px;
  width: 100%;
  color: #b2b2b2;
  font-family: "Kozuca ExtraLight";
}

.select2-selection__rendered {
  padding-left: 0 !important;
  color: #b2b2b2 !important;
}

.select2-container--focus {
  border-color: transparent !important;
}

.select2-results__option {
  font-family: "Kozuca ExtraLight";
  color: #003145 !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #3e8058 !important;
  color: #FFF !important;
}

.img-fluid {
  width: 100%;
}

@media (max-width: 959px) {
  .modulo h2 {
    font-size: 30px !important;
  }
  .footer h3 {
    font-size: 18px !important;
  }
}

@media (max-width: 767px) {
  .banner-slider-slick .slick-dots, .produto-slide-show .slick-dots {
    bottom: 45px;
  }
  .footer .modulo {
    display: table !important;
    width: 100% !important;
    margin: 20px 0 0 0 !important;
  }
  .page .page-title h1 {
    font-size: 30px;
  }
  .page .page-title h2 {
    font-size: 16px !important;
  }
}

@media (max-width: 480px) {
  .modulo h2 {
    font-size: 22px !important;
  }
  .page .page-title h1 {
    font-size: 22px;
  }
}
