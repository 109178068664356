
$colors: (
    one: #003145,    
    two: #3e8058,
    three: #f9f9f9,
    four: #d96100,
    five: #a6a5a5,
    six: #848484,
    seven: #fafafa, 
    eight: #484848,
    nine: #3f7f57,
    ten: #f9f9f9,
    eleven: #146f7c,
    twelve: #32a0ae
);

.btn-one,
.btn-two,
.btn-custom-one{  
    color: #FFF !important;
    box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.2);
    padding: 11px 18px;
    font-size: 13px;
    text-decoration: none;
    outline: none !important;
    font-family: map-get($fonts,three);
    border: 0;
    cursor: pointer !important;
}
.btn-one{
    background: map-get($colors,one); 
}
.btn-two{
    background: map-get($colors,two); 
}
.btn-three{
    background: #FFF;
    outline: none !important;
    border-radius: 7px; 
    color: map-get($colors,two); 
    border: 1px solid map-get($colors,two); 
    font-size: 12px;  
    padding: 12px 30px;
}
.btn-two.radius{
    box-shadow: none !important;
    border-radius: 10px !important; 
    padding: 15px 20px;
}
.btn-custom-one{
    background: map-get($colors,one);
    min-width: 170px;
    text-align: left;
    position: relative;
    display: table;
}
.btn-custom-one span{
    font-size: 10px;
}
.btn-custom-one mat-icon{
    background: map-get($colors,six); 
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    top: 0;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0 0 0 7px;
}
.btn-custom-two{
    border-radius: 30px;
    color: #d96100;
    background: transparent;
    font-family: map-get($fonts,four);
    text-transform: uppercase;
    font-size: 13px;
    padding: 15px 60px;
    border: 2px solid #d96100;
    -webkit-transition: background 300ms ease;
    -moz-transition: background 300ms ease;
    -ms-transition: background 300ms ease;
    -o-transition: background 300ms ease;
    transition: background 300ms ease;
}
.btn-custom-two:hover{
    background: #d96100;
    color: #FFF; 
}