@import "./config.scss";

h2,h3,h4{
    font-family: map-get($fonts,five);
}
header.default-home .menu a{
    color: #FFF !important;
}
header.fixed .menu a{
    color: map-get($colors,one) !important;
}
header.default-home .logo-1{
    display: none;
} 
header.fixed .logo-1{
    display: block;
    margin: 5px 0 0 0;
}
header.fixed{
    background: #FFF !important;
    -webkit-box-shadow: 0 0 20px 0 #B3B3B3;
    box-shadow: 0 0 20px 0 #B3B3B3;
}
header.fixed .content{
    padding: 10px 0 !important;
}
header.default-home .logo-2{
    display: block;
} 
header.fixed .logo-2{
    display: none;
}
.text-center{
    text-align: center;
}
.section-title-h2{
    font-family: map-get($fonts,four);
    font-size: 22px;
    margin: 0 0 10px 0;
}
.seta-box{
    border-radius: 100%;
    background: #FFF;
    display: flex;
    align-items: center;
    width: 60px;
    height: 60px;
    box-shadow: 0 0 10px 0 #d4d4d4;
    cursor: pointer;
}
.seta-box img{
    width: 15px;
    margin: 0 auto;
}
.element-shape{
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.element-shape svg{
    display: block;
    width: calc(160% + 1.3px);
    height: 120px;  
    transform: translateX(-50%);
    position: relative;
    left: 50%;
}
.element-shape-fill{
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg);
}
svg:not(:root) {
    overflow: hidden;
}
.modulo-chamada article strong{
    font-family: map-get($fonts,four);
}
.linha-title{
    display: table;
    margin: 0 auto;
    width: 24px;
    height: 7px;
    background: map-get($colors,four);
    transform: skewY(-16deg) scaleX(1);
}