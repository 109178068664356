@font-face {
    font-family: 'Kozuca Regular';
    src: url('/assets/fonts/KozGoPr6N-Regular-AlphaNum.eot?#iefix') format('embedded-opentype'),  
        url('/assets/fonts/KozGoPr6N-Regular-AlphaNum.otf')  format('opentype'),
        url('/assets/fonts/KozGoPr6N-Regular-AlphaNum.woff') format('woff'), 
        url('/assets/fonts/KozGoPr6N-Regular-AlphaNum.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Kozuca Heavy';
    src: url('/assets/fonts/KozGoPr6N-Heavy-AlphaNum.eot?#iefix') format('embedded-opentype'),  
        url('/assets/fonts/KozGoPr6N-Heavy-AlphaNum.otf')  format('opentype'),
        url('/assets/fonts//KozGoPr6N-Heavy-AlphaNum.woff') format('woff'), 
        url('/assets/fonts/KozGoPr6N-Heavy-AlphaNum.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal; 
}
@font-face {
    font-family: 'Kozuca Medium';
    src: url('/assets/fonts/KozGoPr6N-Medium-AlphaNum.eot?#iefix') format('embedded-opentype'),  
        url('/assets/fonts/KozGoPr6N-Medium-AlphaNum.otf')  format('opentype'),
        url('/assets/fonts/KozGoPr6N-Medium-AlphaNum.woff') format('woff'), 
        url('/assets/fonts/KozGoPr6N-Medium-AlphaNum.ttf')  format('truetype'); 
    font-weight: normal;
    font-style: normal;
 }
@font-face {
    font-family: 'Kozuca Light';
    src: url('/assets/fonts/KozGoPr6N-Light-AlphaNum.eot?#iefix') format('embedded-opentype'),  
        url('/assets/fonts/KozGoPr6N-Light-AlphaNum.otf')  format('opentype'),
        url('/assets/fonts/KozGoPr6N-Light-AlphaNum.woff') format('woff'), 
        url('/assets/fonts/KozGoPr6N-Light-AlphaNum.ttf')  format('truetype'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Kozuca ExtraLight';
    src: url('/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.eot?#iefix') format('embedded-opentype'),  
        url('/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.otf')  format('opentype'),
        url('/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.woff') format('woff'), 
        url('/assets/fonts/KozGoPr6N-ExtraLight-AlphaNum.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Kozuca Bold';
    src: url('/assets/fonts/KozGoPr6N-Bold-AlphaNum.eot?#iefix') format('embedded-opentype'),  
        url('/assets/fonts/KozGoPr6N-Bold-AlphaNum.otf')  format('opentype'),
        url('/assets/fonts/KozGoPr6N-Bold-AlphaNum.woff') format('woff'), 
        url('/assets/fonts/KozGoPr6N-Bold-AlphaNum.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
  }

$fonts : (
    one: "Kozuca Regular",
    two: "Kozuca Light",
    three: "Kozuca ExtraLight", 
    four: "Kozuca Bold",
    five: "Kozuca Medium",
    six: "Kozuca Heavy"
);

/* fallback */
@font-face { 
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/material.woff2') format('woff2');
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
